import type {ApiResponse} from "~/entities/apiResponse";
import { SummaryVisibility } from "~/composables/enums/SummaryVisibility";

export type SummaryRecords = {
    id: string|null,
    question: string,
    hash: string|null,
};
type AddSummariesBody = {
    professions: number[],
    dateBirth: number,
    experienceInProfession: number,
    experience: number,
    schedules: number[],
    records: SummaryRecords[],
    visibility: number
};
type AddSummariesResponse = ApiResponse & {
    summary: { id: string },
    bedQuestions: number[],
};
export type UpdateSummariesBody = {
    professions?: number[],
    dateBirth?: number,
    experienceInProfession?: number,
    experience?: number,
    schedules?: number[],
    records?: SummaryRecords[],
    visibility?: SummaryVisibility
};
type UpdateSummariesResponse = ApiResponse & {
    bedQuestions: number[],
};
type _Get = {
    profession: boolean| null,
    city: boolean| null
};
type GetSummariesQuery = {
    client?: string,
    profession?: number,
    city?: number,
    page?: number,
    get?: _Get
};

export const useApiSummaries = () => {
    return {
        add: async (body: AddSummariesBody) => {
            return await mainFetch('summaries', {
                method: 'POST',
                body
            }, true);
        },
        get: async (query: GetSummariesQuery = {} as GetSummariesQuery) => {
            return await mainFetch('summaries', {
                method: 'GET',
                query
            });
        },
        getOne: async (id: string) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'GET'
            });
        },
        update: async (id: string, body: UpdateSummariesBody) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'PUT',
                body
            }, true);
        },
        delete: async (id: string) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'DELETE'
            });
        }
    };
}
